.missao {
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.missao-icon {
  margin-top: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.missao-valores {
  text-align: start;
  width: 80%;
  margin-top: 20px;
}
.missao-valores p span {
  font-size: 20px;
  color: #5fba6d !important;
  margin-right: 5px;

  font-weight: bolder;
}
.missao-valores p {
  color: #5fba6d !important;
}
#text-valores-missao-grande {
  font-size: 20px;
  margin-top: 50px;
  color: #5fba6d !important;
}
@media (max-width: 600px) {
  .missao {
    height: 900px;
  }
  #text-valores-missao-grande {
    font-size: 18px;
  }
}
.missao-geral-flex {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.missao-geral-flex img {
  width: 80%;
  margin-bottom: 50px;
}

.main-back-img-fundo {
  background: url("../../images/Papel\ de\ parede\ Heather.png");
}
