.main-select-image {
  height: 109vh;
flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-servicos {
  margin-bottom: 300px;
}
@media (max-width: 600px) {
  .main-select-image {
    height: 65vh;
  }
}
