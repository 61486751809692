.main-servicos {
  height: 10vh;
  padding-top: 10%;
  padding-bottom: 20%;
}
.other-services-center {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 10px;
  flex-wrap: wrap;
}
.main-servicos-main {
  width: 100%;
  display: flex;
  height: 1200px;
  align-items: center;
  justify-content: center;
}
@media (max-width: 800px) {
  .other-services-center {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }

  .main-servicos-main {
    height: 100% !important;
  }
}
.servico-tittle-page {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #397639;
  margin-top: 100px;
}

.box-cantina {
  background: url("../../images/Imagem\ cantina.png");
  background-size: cover;
  width: 320px;
  height: 450px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: 0.6s;
}
.box-funeraria {
  background: url("../../images/funeraria-brasilia4.webp");
  background-size: cover;
  width: 320px;
  height: 450px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  transition: 0.6s;
}
.box-funeraria:hover {
  margin-top: -20px;
}

.box-floricultura {
  background: url("../../images/Imagem\ Floricultura.png");
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 320px;
  height: 450px;
  transition: 0.6s;
}
.box-cantina:hover {
  margin-top: -20px;
}

.box-floricultura:hover {
  margin-top: -20px !important;
}
.class-box-servicos-main-others {
  width: 100%;
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.class-box-servicos-main-others .box-floricultura,
.box-cantina,
.box-funeraria {
  margin: 25px;
}
/* 
.box-floricultura,
.box-cantina img {
  width: 200px;
} */
#btn-services-other {
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  cursor: pointer;
  border-radius: 20px;
}
#btn-services-other-green {
  width: 80px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #397639;
  border-radius: 20px 0 0 20px;
}
#btn-services-other-green img {
  width: 30px;
}
#btn-services-other-white {
  width: 170px;
  display: flex;
  align-items: center;
  height: 50px;
  justify-content: center;
}
#btn-services-other-white p {
  margin: 0;
  font-size: 18px;
  margin-left: -20px;
}
.h3-services {
  width: 100%;
  margin-top: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media(min-width:970px){
  .h3-services {
    margin-top: 150px;
  }
}
.h3-services h3 {
  font-size: 40px;
  width: 60%;
}
.box-estrutura-services {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.itens-estrutura-icon {
  width: 300px;
  height: 80px;
  margin: 15px;
  display: flex;
  text-align: start;
  align-items: center;
}
.itens-estrutura-icon p {
  margin-left: 30px;
  font-family: "Lora";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
}
.itens-estrutura {
  display: flex;
  justify-content: center;
}
.box-estrutura-services h2 {
  height: 60px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  color: #1a381a;
}

@media (max-width: 800px) {
  .itens-estrutura {
    display: block;
  }
  .h3-services h3 {
    font-size: 25px;
  }
  .class-box-servicos-main-others {
    flex-direction: column;
    height: 100% !important;
  }
  .cemi-text-p p {
    font-size: 20px !important;
  }
  .div-taxa-de-manutencao {
    height: 520px !important;
  }
  .div-taxa-de-manutencao div p {
    font-size: 25px;
  }
}
.cemi-text-p {
  width: 100%;
  display: flex;
  justify-content: center;
}
.cemi-text-p p {
  font-size: 30px;
  width: 80%;
  margin-bottom: 50px;
}
.div-taxa-de-manutencao {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.div-taxa-de-manutencao h2 {
  font-size: 36px;
  color: #1a381a;
}
.div-taxa-de-manutencao p {
  font-size: 30px;
  width: 80%;
}
.div-taxa-de-manutencao div {
  display: flex;
  justify-content: center;
}
