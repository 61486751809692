.box-services-others {
  width: 250px;
  height: 350px;
  display: flex;
  flex-direction: column;
  margin: 20px;
}

.box-services-text {
  width: 100%;
}
.box-services-text h3 {
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 49px;
  text-align: center;
  text-transform: capitalize;
  color: #397639;
}

.box-services-text p {
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 26px;
  text-align: center;

  color: #000000;
}

.height-box-other-text-main {
  height: 200px;
  display: flex;
  border-top: 2px solid #397639;
}
