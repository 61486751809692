#email,
#name {
  width: 300px;
  height: 30px;
  border: 1px solid black;
  color: white;
  background: transparent;
}

#name::placeholder {
  padding-left: 10px;
}
#email::placeholder {
  padding-left: 10px;
}

#message::placeholder {
  padding-left: 10px;
}
#message {
  width: 300px;
  height: 200px;
}
.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  text-align: start !important;
}

.form-group input{
  width: 400px;
}
.form-group label {
  text-align: start !important;
  margin-left: -260px;
  margin-bottom: 5px;
}
#submit-form {
  width: 100px;
  height: 30px;
  background: transparent;
  border: 1px solid black;
  transition: 0.6s;
  cursor: pointer;
}
#submit-form:hover {
  background-color: rgba(0, 0, 0, 0.247);
}
.form-div-container {
  background-color: #3c783dad;

  left: 0%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  z-index: 1000000;
  overflow-y: hidden;
}
form {
}
@media (max-width: 767px) {
  .form-div-container {
    top: 0;
  }
}
