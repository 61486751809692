.main-area-cliente {
  margin-top: 0;
  width: 80%;
  flex-direction: row-reverse;
  display: flex;
  padding-left: 100px;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
@media (max-width: 700px) {
  .main-area-cliente {
    flex-direction: column-reverse;
    padding-top: 100px;
    width: 100% !important;
    padding-left: 0px !important;
    height: auto !important;
  }
  .area-text-cliente p:nth-child(3) {
    width: 100% !important;
    font-family: "Lora";
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 20px !important;
    color: black !important;
  }

  .area-text-cliente h2 {
    font-family: "DM Serif Display";
    font-style: normal;
    font-weight: 400;
    font-size: 28px !important;
    line-height: 26px !important;
  }

  .area-img-cliente img {
    width: 180px !important;
    margin-bottom: 10px;
  }
  #atualizar-agora {
    width: 180px !important;
  }
}
.area-img-cliente {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.area-img-cliente img {
  width: 300px;
}
.area-text-cliente {
  display: flex;
  text-align: start;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}
.area-text-cliente p,
h2 {
  text-align: start !important;
}

.area-text-cliente h2 {
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 66px;
}
.area-text-cliente p:nth-child(3) {
  width: 80%;
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 41px;
  color: black !important;
}
#atualizar-agora {
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 33px;
  text-align: center;
  width: 220px;
  height: 45px;
  background: #397639;
  border-radius: 59px;
  color: white;
  cursor: pointer;
  transition: 0.6s;
}
#atualizar-agora:hover {
  opacity: 0.7;
}
.close {
  font-size: 30px;
  margin-left: 20px;
  color: white;
  cursor: pointer;
}
