.main-lost {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #5fba6d !important;
}
.main-lost-second {
  margin-top: 100px;
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  text-align: start;
  color: #5fba6d !important;
}
.main-lost-second ul {
  display: flex;
  margin-left: 50px;
  color: #5fba6d !important;
  flex-direction: column;
}

.main-lost-second ul li {
  color: black;
  font-size: 14px;
  color: #5fba6d !important;
  margin: 5px;
}
.main-lost-second h3 {
  color: black;
  font-size: 16px;
  margin-left: 20px;
  color: #5fba6d !important;
  width: 80%;
}
.main-lost-second h2 {
  font-size: 18px;
  margin: 5px;
  color: #5fba6d !important;
}
#fale-conosco {
  margin-left: 50px;
  margin-top: 50px;
  width: 200px;
  height: 50px;
  font-weight: bold;
  font-size: 16px;
  color: white;
  border-radius: 20px;
  margin-bottom: 50px;
  background-color: #397639;
  cursor: pointer;
  transition: 0.6s;
}
#fale-conosco:hover {
  opacity: 0.8;
}
@media (max-width: 600px) {
  .main-lost-second ul li {
    color: black;
    font-size: 10px;
    margin: 5px;
  }
  .main-lost {
    align-items: center;
  }
  #fale-conosco {
    margin-left: 0;
  }
  .main-lost-second h3 {
    font-size: 12px;
  }
  .main-lost-second h1 {
    font-size: 20px;
  }
}
.fale {
  display: flex;
}
.box-container-box-cartorio {
  width: 100%;
  display: flex;

  justify-content: center;
}
.box-cartorio {
  align-items: center;
  justify-content: flex-start;
  text-align: start;
  display: flex;
  border: 1px solid #5fba6d !important;
  width: 350px;
  margin: 10px;
  height: 80px;
}
.box-cartorio p {
  color: #5fba6d !important;
  font-weight: bold;
  font-size: 10px;
  margin-top: 0;
}
@media (max-width: 600px) {
  .box-container-box-cartorio {
    flex-direction: column;
    align-items: center;
  }
  .fale {
    flex-direction: column;
  }
  #fale-conosco {
    margin-bottom: 0;
    margin-top: 0;
    margin: 10px;
  }
}
