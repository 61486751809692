.Footer-text {
  width: 100%;
  background-color: #5fba6d;
  justify-content: center;

  align-items: center;
  display: flex;
  height: 35vh;
}
@media (max-width: 600px) {
  .Footer-text {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .Footer-text img {
    margin-bottom: 20px;
    width: 180px;
  }
  .Footer-text div ul {
    text-align: center;
    display: flex;
    width: 300px;

    justify-content: center;
  }
  .Footer-text div ul li {
    width: 100%;
  }
  .Footer-text div ul li a {
    font-size: 13px;
  }
}
.Footer-text ul {
  list-style: none;
  flex-direction: column;
  text-align: start;
}

.Footer-text ul li {
  color: white;
  margin: 3px;
  transition: 0.6s;
}
.Footer-text div,
ul {
  display: flex;
  justify-content: center;
  width: 100%;
}
.Footer-all {
  width: 100%;
  height: 30px;
  display: flex;
  font-weight: 500;
  justify-content: center;
  align-items: center;
}
ul li a {
  text-decoration: none;
  font-size: 20px;
  color: white;
}

.Footer-text ul li:hover {
  opacity: 0.8s;
}
