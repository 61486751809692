.header {
  width: 100%;
  display: flex;
  background-color: transparent;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 800px) {
  .header {
    display: none;
  }
  .menu-hamburguer {
    display: block !important;
  }
}
.header a {
  margin: 15px;
  color: #3c783d;
  font-weight: 400;
  font-size: 20px;
  text-decoration: none;
  cursor: pointer;
}
.header a:first-child {
  margin-left: 100px;
}
.header-box-wpp {
}

.header-box-mail {
}

.header-box-area {
}
.header-box {
  width: 270px;
  display: flex;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  color: white;
  align-items: center;
  background: #397639;
  border-radius: 20px;
  box-shadow: 0px 5px 5px 0px grey;
  height: 50px;
  margin-left: 10%;
  transition: 0.5s;
  cursor: pointer;
}
.header-box a {
  margin-left: 0 !important;
  margin-right: 0 !important;
  color: white;
}
.header-box div {
}
.header-main {
  transition: 0.6s;
  display: flex;
  background: transparent;
  justify-content: flex-start;
  width: 100%;
  top: 0px;
  position: fixed;
  z-index: 1000;
}

@media (max-width: 600px) {
  .header-main {
    height: 40px;
    align-items: center;
    padding-left: 20px;
  }
}
@media(min-width:1350px){
  .header-box{
    margin-left: 29%;
  }
}
.header-box:hover {
  opacity: 0.9;
}
.menuFixo {
  background: #5fba6d !important;
}
#header-main-fixed {
  background: #5fba6d !important;
}

#header-main-fixed a {
  color: white !important;
}
#area-client-header {
  box-shadow: none !important;
}
.menu-hamburguer {
  display: none;
}
