#button-wpp {
  cursor: pointer;
  position: fixed;
  bottom: 10%;
  background-color: transparent;
  left: 93%;
}
@media (max-width: 767px) {
  #button-wpp {
    left: 75%;
  }
}
