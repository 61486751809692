.main {
  margin-top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: url("../../images/Papel\ de\ parede\ Heather.png");
}
.main div {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.main div h1,
h3,
p {
  color: #397639;
  margin-top: 15px;
}
.main div .bem-vindo {
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
}
.main div .p-main-two {
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
}
.p-main-one {
  font-size: 35px;
  font-family: "DM Serif Display";
  font-style: normal;
  font-weight: bold;
  text-align: center;
  width: 67%;

  font-weight: 500;
}
.p-main-two {
  font-size: 25px;
  font-size: 400;
}
.btn-home-main {
  display: flex !important;
  justify-content: center;
  flex-direction: initial !important;
  flex-wrap: wrap;
  width: 100%;
}
.btn-home-main button {
  margin: 20px;
  margin-top: 50px !important;
  cursor: pointer;
}
main div .btn-home-main button {
  width: 200px;
  height: 40px;
  left: 388px;
  top: 720px;
  color: white;

  background: #5fba6d;
  border-radius: 59px;
}
main div .btn-home-main button:last-child {
  width: 200px;
  height: 40px;
  left: 388px;
  top: 720px;
  color: white;

  background: #397639;
  border-radius: 59px;
}
.icons-main {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 900px) {
  .icons-main {
    flex-direction: column;
  }
  .p-main-one {
    font-size: 30px;
  }

  .btn-home-main button {
    margin: 0px;
    margin-top: 20px !important;
  }
  .btn-home-main {
    flex-direction: column !important;
  }
  .text-main-text-home video {
    width: 90%;
  }
}
.icons-main img {
  width: 100px;
  margin: 50px;
  margin-bottom: 0px;
}
.icons-main div {
  display: flex;
  margin: 5px;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  transition: 0.4s;
  /* margin-bottom: 40px; */
}
.icons-main div:hover {
  background-color: #5fba6d6c;
}
.text-main-text-home {
  text-align: center;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 80px;
}
.text-main-text-home p {
  width: 75%;
  display: flex;
  color: #5fba6d !important;
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
}

@media only screen and (max-width: 767px) and (orientation: landscape) {
  .main {
    /* Estilos específicos para a orientação paisagem do celular */
    /* Insira aqui as regras de estilo para .main */

    height: auto;
  }

  .main div {
    /* Estilos específicos para a orientação paisagem do celular */
    /* Insira aqui as regras de estilo para .main div */
    height: auto;
  }

  .p-main-one {
    /* Estilos específicos para a orientação paisagem do celular */
    /* Insira aqui as regras de estilo para .p-main-one */
    height: auto;
  }

  .p-main-two {
    /* Estilos específicos para a orientação paisagem do celular */
    /* Insira aqui as regras de estilo para .p-main-two */
    height: auto;
  }

  .btn-home-main {
    /* Estilos específicos para a orientação paisagem do celular */
    /* Insira aqui as regras de estilo para .btn-home-main */
  }

  .btn-home-main button {
    /* Estilos específicos para a orientação paisagem do celular */
    /* Insira aqui as regras de estilo para .btn-home-main button */
  }

  .icons-main {
    /* Estilos específicos para a orientação paisagem do celular */
    /* Insira aqui as regras de estilo para .icons-main */
  }

  .icons-main img {
    /* Estilos específicos para a orientação paisagem do celular */
    /* Insira aqui as regras de estilo para .icons-main img */
  }

  .icons-main div {
    /* Estilos específicos para a orientação paisagem do celular */
    /* Insira aqui as regras de estilo para .icons-main div */
    height: auto;
  }

  .text-main-text-home {
    /* Estilos específicos para a orientação paisagem do celular */
    /* Insira aqui as regras de estilo para .text-main-text-home */
    height: auto;
  }

  .text-main-text-home p {
    /* Estilos específicos para a orientação paisagem do celular */
    /* Insira aqui as regras de estilo para .text-main-text-home p */
  }
  div {
    height: auto;
  }
  .Footer-text {
    height: auto;
  }
  .class-box-servicos-main-others {
    height: auto !important;
    flex-direction: column;
  }
  /* Insira aqui outras regras de estilo específicas para a orientação paisagem do celular */
}
