#crematorio-crm p,
h1 {
  text-align: justify;
}
#crematorio-crm p {
  font-size: 22px;
}
#crematorio-crm h1 {
  color: #397639;
}
#crematorio-crm {
  padding-top: 50px;
}
#crm-conter {
  margin: 50px;
}
#crematorio-crm #crm-conter img {
  margin-top: 20px;
  width: 70%;
}
