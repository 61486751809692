.Missas {
  height: 500px;
  width: 100%;
  display: flex;

  align-items: center;
  flex-direction: column;
}
.Missas img {
  margin-top: 120px;
}
.Missas p {
  width: 60%;
  color: #5fba6d !important;
  margin-top: 25px;

  font-size: 20px;
  font-weight: bold;
}
@media (max-width: 600px) {
  .Missas p {
    width: 80%;
    font-size: 16px;
  }
  .Missas {
    height: 600px;
  }
}
.missa-flex-column {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.missa-flex-column img {
  width: 80%;
  margin-bottom: 50px;
}
