.side {
  background-color: #5fba6d;
  height: 100vh;
  width: 100%;
  align-items: center;

  position: absolute;

  top: 0%;
  display: flex;
  flex-direction: column;
  left: 0%;
}
.side a {
  margin: 20px;
  text-decoration: none;
  color: white;
}
.x-close {
  width: 100%;
  display: flex;
  padding-left: 10px;
  padding-top: 4px;
  justify-content: flex-start;
}
